import React from 'react';
import './Homepage.css';
import HeroSection from "./HeroSection";
import LaatsteOntwikkelingen from "./LaatsteOntwikkelingen";

function Homepage() {
    return (
        <div className="homepage-container">
            {/*<h1>aan het uitpraten...</h1>*/}
            {/*<HeroSection />*/}
            {/*<LaatsteOntwikkelingen />*/}



        </div>
    );
}

export default Homepage;
/*import Packages from "./Packages";
import WhyChooseUs from "./WhyChooseUs";
import HomepageRouteToPricing from "./HomepageRouteToPricing";
import ActionRouteToConsultancy from "./ActionRouteToConsultancy";
import HomepageRouteToConsultancy from "./HomepageRouteToConsultancy";
import SoftwareRequest from "./SoftwareRequest";*/

/*
<SoftwareRequest />
*/

{/*<WhyChooseUs />*/}
{/*<HomepageRouteToPricing />*/}
{/*<Packages />*/}
{/*<HomepageRouteToConsultancy />*/}
